import * as Ratings from '.'
import * as Palette from './palette'

export default new Map([
    [Ratings.LOW, Palette.GREEN],
    [Ratings.MODERATE, Palette.YELLOW],
    [Ratings.CONSIDERABLE, Palette.ORANGE],
    [Ratings.HIGH, Palette.RED],
    [Ratings.EXTREME, Palette.BLACK],
    [Ratings.NO_RATING, Palette.WHITE],
    [Ratings.SPRING, Palette.BLUE],
    [Ratings.EARLY_SEASON, '#0072C6'],
])

export const COLOUR_BLIND_PALETTE = new Map([
    [Ratings.LOW, '#fcffa4'],
    [Ratings.MODERATE, '#f98e09'],
    [Ratings.CONSIDERABLE, '#bc3754'],
    [Ratings.HIGH, '#57106e'],
    [Ratings.EXTREME, '#000004'],
    [Ratings.NO_RATING, 'black'],
    // This is the same as non-CB palette, but we should probably change it
    [Ratings.SPRING, Palette.BLUE],
    [Ratings.EARLY_SEASON, '#0072C6'],
])
