// This is a list of Mixpanel events to keep them consistent across our apps. Such strings are attached to their corresponding HTML element via the `data-event-name` attribute and are then passed to Mixpanel
export const ICE_CLIMB_OPENED = 'Ice climb opened'
export const FORECAST_OPENED = 'Forecast opened'
export const FORECAST_CLOSED = 'Forecast closed'
export const FATAL_ACCIDENT_OPENED = 'Fatal Accident opened'
export const MIN_REPORT_OPENED = 'Mountain Information Network report opened'
export const MCR_OPENED = 'Mountain Conditions Report opened'
export const WEATHER_STATION_OPENED = 'Weather station opened'
export const FORECAST_PHOTO_OPENED = 'Forecast photo opened'
export const MAP_LANDING = 'Map landing event'
export const PLACENAME_SEARCH = 'Placename search event'
export const PAGE_UNLOADED = 'Page unloaded'
export const DANGER_RATINGS_EXPLAINED_OPENED = 'Danger Ratings Explained opened'
export const DANGER_RATINGS_MODAL_OPENED = 'Danger Ratings Modal opened'
export const DANGER_RATINGS_GLOSSARY_OPENED = 'Danger Ratings Glossary opened'
export const UNIDENTIFIED_EVENT = 'Unidentified event; be sure to assign `data-event-name` to the HTML element.'
