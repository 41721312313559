import React from 'react'
import { BLACK, WHITE, GREEN, YELLOW, ORANGE, RED } from 'constants/products/forecast/ratings/palette'

// TODO Ask Brent to help simplifying these shapes!
// TODO Splitting these drawings into smaller reusable shapes.
// Frame
// Summits/Snowcaps
// Mountains
// Exclamation
// Check
// ...

export function Low() {
    return (
        <g width="81.62" height="81.62">
            <path
                fill={BLACK}
                d="M81.23 39.9L41.73.37c-.52-.5-1.34-.5-1.85 0L.38 39.87c-.25.26-.38.6-.38.94 0 .4.15.7.4 1l39.5 39.5c.24.25.57.4.92.4.34 0 .67-.15.92-.4l39.5-39.5c.5-.5.5-1.33 0-1.84z"
            />
            <path
                fill={WHITE}
                d="M68.9 29.8l-4.84-4.84L48.9 9.76 43.7 4.6l-1.54-1.55-.97-.98c-.1-.1-.3-.16-.4-.16-.18 0-.3.1-.4.2l-.74.76L17.53 25l12.63-7.18 9.24 4.33 10.1-7.83.44.33L67 31.7c.52.53 1.37.53 1.9 0 .53-.52.53-1.37 0-1.9z"
            />
            <path
                fill={GREEN}
                d="M2.1 41.1l38.34 38.33c.1.1.22.15.36.15.13 0 .26-.06.35-.15L79.5 41.1c.08-.1.12-.2.13-.32H1.97c0 .12.05.23.14.32z"
            />
            <path
                fill={BLACK}
                d="M64.03 46.17c.47.88-3.7 3.84-9 9.15-6 6-13.36 14.44-14.45 15.14-1.2.75-2.77.2-3.28-.58-.27-.4-5.97-9.66-5.97-10.04 0-.77.45-1.47 1.34-2.1.9-.65 1.8-1.52 2.63-1.12.26.13 3.87 6.3 4.85 6.28 1.96-.04 16.22-17.67 19-17.93.65-.07 4.08-.4 4.88 1.2z"
            />
        </g>
    )
}

export function Moderate() {
    return (
        <g width="81.62" height="81.61">
            <path
                fill={BLACK}
                d="M81.23 39.9L41.73.37c-.5-.5-1.34-.5-1.84 0L.3 39.87c-.24.26-.37.6-.37.94 0 .4.14.7.4 1l39.5 39.5c.24.3.57.4.9.4s.7-.1.93-.3l39.5-39.5c.5-.5.5-1.3 0-1.8z"
            />
            <path
                fill={YELLOW}
                d="M2.1 41.1l38.34 38.33c.1.1.23.15.36.15.13 0 .26-.05.36-.15L79.5 41.1c.08-.1.12-.2.13-.32H1.97c0 .12.06.23.14.32z"
            />
            <path
                fill={BLACK}
                d="M37.08 46.26l.74 15.1c0 2.17 5.9 2.17 5.9 0l.73-15.1c0-2.4-7.37-2.4-7.37 0zm6.9 21.47c0 1.76-1.43 3.18-3.18 3.18-1.76 0-3.18-1.4-3.18-3.1s1.42-3.2 3.18-3.2c1.75 0 3.18 1.5 3.18 3.2zm16-49.43c-.85.85-1.27 1.96-1.27 3.06s.5 2.2 1.3 3.06c.7.63 1.5 1.03 2.4 1.18.1 1.03.5 2 1.2 2.74.3.27.6.5 1 .68.2.3.4.6.7.86.6.52 1.2.9 1.9 1.12.1 1.4.7 2.7 1.7 3.7 2.3 2.3 6 2.3 8.3 0 1.1-1.1 1.7-2.57 1.7-4.14 0-1.57-.6-3.05-1.7-4.15-.8-.8-1.9-1.3-3-1.5.35-.7.57-1.4.57-2.1 0-1.2-.46-2.4-1.4-3.3-1.75-1.7-4.56-1.8-6.43-.2-.17-.23-.36-.5-.6-.72-1.7-1.66-4.4-1.66-6.1 0z"
            />
            <path
                fill={WHITE}
                d="M69.33 34.05c-.9-.9-1.4-2.1-1.44-3.4l-.1-.34h-.4c-.7-.1-1.3-.5-1.8-1-.3-.2-.4-.5-.6-.7l-.1-.1h-.2c-.3-.1-.6-.3-.84-.6-.6-.6-.95-1.5-.9-2.4l.06-.43-.5-.04c-.8-.07-1.6-.4-2.2-1-1.32-1.3-1.32-3.46 0-4.8 1.3-1.3 3.46-1.3 4.8 0 .28.3.5.65.68 1.03l.3.6.57-.6.1-.07c1.5-1.5 3.96-1.5 5.46 0 1.35 1.3 1.5 3.4.45 4.9l-.45.7.8.05c1.15.1 2.26.6 3.1 1.45 1.9 1.92 1.9 5.05 0 7-1.94 1.9-5.07 1.9-7 0z"
            />
            <path
                fill={WHITE}
                d="M71.27 32.16l-7.2-7.2-15.2-15.2L43.7 4.6l-1.55-1.54-.98-1c-.1-.08-.24-.14-.37-.14-.14 0-.27.06-.37.15l-.73.74L17.52 25l12.64-7.17 9.23 4.36 10-7.9.4.3L69.3 34c.5.52 1.34.52 1.9 0 .5-.54.5-1.4 0-1.93z"
            />
            <path
                fill={BLACK}
                d="M70.9 26.55c-.35-.44-1.06-.74-1.6-.8-.03 0-.06.02-.07.06 0 .1 0 .1.04.1 1.17.5 1.76 1.5 1.66 2.7-.12 1.3-.77 2-2.1 2.4-.04 0-.06.1-.06.1s.03.1.06.1c.53.1 1.3-.1 1.73-.4.54-.42.96-1.1 1.03-1.9v-.23c0-.64-.3-1.3-.7-1.8z"
            />
        </g>
    )
}

export function Considerable() {
    return (
        <g width="98.38" height="81.61">
            <path
                fill={BLACK}
                d="M91.37 26.73c-.45 0-.92.1-1.38.2-1-3.2-3.9-5.42-7.3-5.42-3.2 0-6 2-7.1 4.9-.9-.4-1.8-.6-2.7-.6-1.7 0-3.2.7-4.3 1.7L41.7.4c-.5-.5-1.33-.5-1.84 0L.36 39.88c-.26.25-.4.6-.4.93 0 .4.15.7.4 1l39.5 39.5c.24.3.57.4.9.4.36 0 .7-.1.94-.3l39.5-39.5c.3-.2.4-.6.4-.9h9.8c3.9 0 7-3.1 7-7 0-3.86-3.1-7-7-7z"
            />
            <path
                fill={WHITE}
                d="M91.37 27.66c-.52 0-1.05.08-1.58.22l-.5.12-.1-.46c-.8-3-3.4-5.1-6.5-5.1-3 0-5.5 1.9-6.4 4.7l-.2.55-.5-.3c-.9-.5-1.8-.8-2.7-.8-2 0-3.7 1-4.6 2.6L64 24.9 48.8 9.7l-5.4-5.4L42.1 3l-.96-.96c-.1-.1-.24-.15-.37-.15-.14 0-.27 0-.37.1l-.72.7L17.5 24.9l12.63-7.17 9.24 4.34 6.92-5.23 17.3 17.3c.6.68 1.1 1.3 1.5 1.63l.4.4c1.3 1.3 3.7 3.72 6.4 3.72h19.4c3.3 0 6-2.8 6-6.2 0-3.4-2.7-6.1-6.1-6.1z"
            />
            <path
                fill={BLACK}
                d="M48.5 15.23L69.27 36c.28.28.73.28 1 0 .3-.28.3-.73 0-1L49.63 14.32l-1.13.9zM82.64 33.4c-.18-1.13-.8-2.16-2.1-2.8-1.16-.54-2.72-.6-3.83-.22-.9.3-1.8 1-2.2 1.8 0 .05 0 .13.1.16.1.04.1.03.2 0 1.5-1.5 3.6-1.62 5.1-.9 1.8.88 2.6 2.38 2.3 4.63 0 .06.1.12.1.14.1.1.1 0 .2 0 .4-.8.5-1.8.4-2.7z"
            />
            <path
                fill={ORANGE}
                d="M2.1 41.1l38.34 38.33c.1.1.22.15.36.15.13 0 .26-.05.36-.15L79.5 41.1c.08-.1.12-.2.13-.32H1.97c0 .1.05.23.14.32z"
            />
            <g fill={BLACK}>
                <path d="M31.88 45.2l.73 15.1c0 2.17 5.9 2.17 5.9 0l.8-15.1c0-2.4-7.3-2.4-7.3 0z" />
                <circle cx="35.59" cy="66.67" r="3.18" />
            </g>
            <g fill={BLACK}>
                <path d="M42.27 45.2L43 60.3c0 2.17 5.9 2.17 5.9 0l.73-15.1c0-2.4-7.36-2.4-7.36 0z" />
                <circle cx="45.99" cy="66.67" r="3.18" />
            </g>
        </g>
    )
}

export function High() {
    return (
        <g width="113.31" height="81.62">
            <path
                fill={BLACK}
                d="M107.82 25.12l.04-.46c0-2.47-2.02-4.5-4.5-4.5-.53 0-1.05.14-1.55.32-.8-3.6-3.9-6.16-7.7-6.16-3.3 0-6.3 2.14-7.4 5.26-1.2-.7-2.6-1.1-4.1-1.1-3.7 0-7 2.54-8 6.1-.9-.7-2.1-1.1-3.3-1.1-1.7 0-3.3.8-4.3 2.02L41.8.38c-.5-.5-1.4-.5-1.9 0L.3 39.88c-.24.26-.37.6-.37.93 0 .4.14.7.4 1l39.5 39.5c.24.3.56.4.9.4s.7-.1.93-.4l39.5-39.5c.27-.2.4-.6.37-.9h23.7c4.42 0 8-3.6 8-8.1 0-3.4-2.24-6.5-5.48-7.5z"
            />
            <path
                fill={WHITE}
                d="M107.2 25.87l-.36-.14.1-1.07c0-1.96-1.6-3.57-3.58-3.57-.58 0-1.14.1-1.66.4l-.56.3-.1-.7c-.55-3.5-3.47-5.9-6.94-5.9-3.13 0-5.9 2.1-6.76 5.1l-.17.6-.52-.4c-1.22-.8-2.63-1.2-4.08-1.2-3.56 0-6.64 2.5-7.33 6l-.16.8-.6-.6c-.9-.9-2.08-1.3-3.3-1.3-2.08 0-3.84 1.3-4.54 3.1L41.17 2c-.1-.1-.24-.1-.37-.1-.14 0-.27.1-.37.2l-.73.8L17.52 25l12.64-7.18 9.23 4.34 3.6-2.7 14.2 14.2c1.3 1.68 5.6 6.28 10.3 6.28h37.8c3.9 0 7.1-3.2 7.1-7.2 0-3.14-2.2-5.94-5.2-6.8z"
            />
            <path
                fill={BLACK}
                d="M69.94 34.65l-20.3-20.27-1.17.87 20.46 20.4c.28.28.74.28 1 0 .3-.27.3-.73 0-1zM46.32 16.87l-1.14.9 18 17.98c.28.28.73.28 1 0 .3-.3.3-.73 0-1.02L46.33 16.87z"
            />
            <path
                fill={RED}
                d="M2.1 41.1l38.34 38.33c.1.1.23.15.36.15.13 0 .26-.05.36-.15L79.5 41.1c.08-.1.12-.2.13-.32H1.97c0 .12.06.23.14.32z"
            />
            <path
                fill={BLACK}
                d="M53.35 46.43c.1 1.04-2.12 3.27-3.6 4.83-1.45 1.55-3.1 3.6-4.4 5.2 1.14 1.18 2.3 2.33 3.5 3.5 1.18 1.14 2.4 2.3 3.64 3.42-.2.7-1.1 1.55-1.5 1.6-.2.13-1.8 2.4-3.5 2 0 0-2.5-1.82-3.4-2.77-.9-.9-2.5-2.2-3.4-3.3-1.3 1.5-5.6 5.4-6.5 6.7 0 0-1.5.6-3.4-1.4-.3-.3-.6-.6-.8-1 0-.2-1.3-1.6-.4-2.5 1.1-1.1 5.5-5.2 6.8-6.5-1.2-1.6-6.7-8.6-6.3-9.7.2-.7 2.7-2 3.2-2.1l1.4-.3c.5.3 5.4 6.6 6.2 7.5 1.5-1.6 6.2-6.7 7.9-8.1.3.18.7.3 1 .56.3.2 2.1 1.8 2.1 1.8l1 1zm37.23-19.37c-1.73-1.5-3.66-2.07-5.67-2-1.5.06-3 .7-4 1.72v.14c.1.05.1.06.2.03 3.1-1.8 6.2-1.3 8.7.87 2.5 2.27 2.9 4.98 1.4 8.47 0 0 0 .1.1.1h.2c1-1.1 1.6-2.5 1.6-4.1 0-2-.43-3.9-2.2-5.4zm-9.22 6.34c-.28-1.16-1.03-2.18-2.25-2.8-1.1-.56-2.5-.6-3.6-.23-.9.3-1.7 1.02-2.1 1.8 0 .07 0 .14.1.18.1.03.2.02.2-.02 1.5-1.4 3.3-1.74 5.1-.88 1.9.9 2.6 2.38 2.3 4.63 0 .05.1.12.1.14.1 0 .1 0 .2-.07.4-.8.5-1.83.3-2.76z"
            />
        </g>
    )
}

export function Extreme() {
    return (
        <g width="85.16" height="61.43" transform="scale(1.35)">
            <path
                d="M60.51,30.72h-.09c.17,0,.26-.21,.14-.33L30.85,.69c-.08-.08-.2-.08-.27,0L.88,30.39c-.12,.12-.04,.33,.14,.33h-.09c-.14,0-.21,.17-.11,.27l29.79,29.79c.06,.06,.16,.06,.22,0l29.79-29.79c.1-.1,.03-.27-.11-.27Z"
                fill="#231f20"
            />
            <rect
                x="9.39"
                y="9.39"
                width="42.66"
                height="42.66"
                transform="translate(30.72 -12.72) rotate(45)"
                fill="none"
                stroke="#231f20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.1"
            />
            <path
                d="M39.39,29.1c0-.54-.31-1.13-.73-1.45-.91-.69-2.65-.92-3.54,0-1.65,1.73-3.33,3.43-5,5.14-1.09-1.42-2.12-2.88-3.21-4.3-.76-.99-2.31-1.33-3.42-.74-1,.53-1.69,1.77-.9,2.8,1.43,1.87,2.77,3.81,4.27,5.63-.43,.46-.86,.91-1.29,1.36-.52,.55-1.04,1.11-1.55,1.67-.1,.11-.2,.22-.31,.33,0,0-.18,.2-.25,.27-.07,.07-.23,.24-.25,.26-.14,.15-.28,.3-.43,.45-.4,.42-.73,.83-.73,1.45,0,.54,.31,1.13,.73,1.45,.91,.69,2.66,.92,3.54,0,1.1-1.15,2.18-2.31,3.26-3.47,.13-.14,.25-.27,.38-.41,1.36,1.33,2.83,2.57,4.42,3.57,.99,.62,2.79,.26,3.42-.74,.73-1.15,.13-2.16-.9-2.8,.58,.37-.15-.13-.29-.23-.24-.18-.48-.37-.71-.56-.47-.38-.92-.78-1.37-1.19-.49-.43-.97-.87-1.43-1.33,1.86-1.9,3.73-3.79,5.57-5.71,.4-.42,.73-.83,.73-1.45Z"
                fill="#ed1c24"
            />
            <path
                d="M81.38,19.8c-.06-2.4-1.94-4.43-4.71-4.39,.17-.64,.31-1.43,.31-2.13,0-4.24-3.44-7.68-7.68-7.68-2.64,0-4.97,1.34-6.35,3.37-.9-.67-2-1.07-3.21-1.07-2.7,0-4.93,1.99-5.32,4.59-1.01-1.1-2.45-1.79-4.06-1.79-2.61,0-4.76,1.71-5.35,4.14L30.72,.55,10.91,20.36l11.72-6.68,6.81,3.86,2.91-2.59,15.47,15.24h18.8l12.77-.02h.09c2.73-.29,5.12-2.62,5.12-5.42,0-2.21-1.33-4.11-3.23-4.95Z"
                fill="#fff"
                stroke="#231f20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.1"
            />
            <path
                d="M55.49,22.06c.87-.95,2.29-1.28,3.53-.72,1.31,.59,1.99,1.99,1.74,3.34"
                fill="#fff"
                stroke="#231f20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.1"
            />
            <path
                d="M61.91,18.07c1.8-1,4.11-.79,5.7,.69,1.68,1.56,2.01,4.02,.95,5.94"
                fill="#fff"
                stroke="#231f20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.1"
            />
            <line
                x1="36.84"
                y1="10.99"
                x2="52.92"
                y2="26.62"
                fill="#fff"
                stroke="#231f20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.1"
            />
            <line
                x1="34.62"
                y1="12.93"
                x2="48.26"
                y2="26.19"
                fill="#fff"
                stroke="#231f20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.1"
            />
        </g>
    )
}

export function NoRating() {
    return (
        <g width="82.46" height="82.47">
            <path d="M82.07 40.3L42.15.38c-.5-.5-1.34-.5-1.86 0L.3 40.3c-.24.25-.37.58-.37.94 0 .34.14.67.4.92l39.9 39.92c.25.25.6.38.93.38.35 0 .7-.13.93-.38l40-39.92c.5-.5.4-1.34-.1-1.86z" />
            <path
                fill={WHITE}
                d="M69.6 30.1l-4.87-4.88L49.38 9.88l-5.23-5.24-1.57-1.56-.98-1c-.1-.1-.24-.15-.38-.15s-.27.06-.37.16l-.74.7-22.4 22.4L30.5 18l9.33 4.4L50 14.45l.43.32 17.25 17.26c.53.53 1.4.53 1.93 0 .6-.54.6-1.4 0-1.93zM2.13 41.53l38.73 38.72c.1.1.22.16.36.16.14 0 .27 0 .37-.1l38.7-38.7c.1-.1.1-.2.1-.3H2c0 .2.04.3.13.4z"
            />
            <path d="M41.2 66.07c-.8 0-1.55-.28-2.1-.82-.62-.6-.88-1.44-.88-2.36 0-1.1.17-2.1.53-2.9.34-.8.8-1.6 1.37-2.2.54-.6 1.25-1.3 2.13-2 .75-.7 1.3-1.2 1.6-1.5.3-.3.53-.6.73-1 .17-.3.25-.6.25-1 0-.8-.25-1.3-.8-1.8-.57-.5-1.25-.8-2.2-.8-1.2 0-1.9.3-2.33.7-.5.53-1 1.4-1.4 2.6-.4 1.52-1.55 2.6-3.03 2.56-.85 0-1.66-.34-2.24-.95-.56-.6-.9-1.3-.9-2.1 0-1.3.4-2.6 1.2-3.83.8-1.3 1.97-2.33 3.45-3.14 1.52-.84 3.28-1.25 5.24-1.24 1.8 0 3.45.33 4.9 1.02 1.42.7 2.56 1.64 3.36 2.86.8 1.2 1.2 2.6 1.2 4 0 1.14-.23 2.16-.7 3.05-.45.84-.98 1.56-1.6 2.17-.6.6-1.6 1.5-3.06 2.8l-.9.9c-.23.3-.37.5-.45.65-.1.2-.17.36-.22.54l-.27 1.14c-.2 1.43-1.33 2.5-2.75 2.5h-.14z" />
            <circle cx="41.36" cy="71.03" r="3.4" />
        </g>
    )
}

export function NoForecast() {
    return (
        <g transform="scale(0.78)">
            <path
                d="m94.4 53.1-39.5-39.5c-.5-.5-1.3-.5-1.8 0l-39.5 39.5c-.2.2-.4.6-.4.9s.1.7.4.9l39.5 39.5c.2.2.6.4.9.4s.7-.1.9-.4l39.5-39.5c.5-.5.5-1.3 0-1.8z"
                fill={BLACK}
            />
            <path
                d="m82.1 43-4.8-4.8-15.2-15.2-5.2-5.2-1.5-1.5-1-1c-.1-.1-.2-.2-.4-.2-.1 0-.3.1-.4.2l-.7.7-22.2 22.2 12.6-7.2 9.2 4.4 10.1-7.8.4.3 17.1 17.1c.5.5 1.4.5 1.9 0 .6-.6.6-1.5.1-2z"
                fill={WHITE}
            />
            <path
                d="m15.3 54.3 38.3 38.3c.1.1.2.2.4.2s.3-.1.4-.2l38.3-38.3c.1-.1.1-.2.1-.3h-77.6c0 .1 0 .2.1.3z"
                fill={WHITE}
            />
            <path
                d="m2 53.4c0-27.4 21.4-50.2 48.6-52.1 30.2-2.1 55 21.3 56 50.3 1 29.1-21.5 52.3-48.7 54.1-30.1 2.1-56-21.7-55.9-52.3zm17.1-19.8c-9.7 16.6-6.4 40.3 12.2 53.3 18 12.6 39.7 7.4 51-4.1-21.1-16.4-42.1-32.8-63.2-49.2zm7.2-9.3c21.1 16.4 42.2 32.8 63.2 49.2 9.2-15.6 7-38.7-11-52.4-16.5-12.5-38.7-10-52.2 3.2z"
                fill={RED}
            />
            {/* TODO: background so that it doesn't look cluttered */}
            {/* <path
                d="m2 53.4c0-27.4 21.4-50.2 48.6-52.1 30.2-2.1 55 21.3 56 50.3 1 29.1-21.5 52.3-48.7 54.1-30.1 2.1-56-21.7-55.9-52.3zm17.1-19.8c-9.7 16.6-6.4 40.3 12.2 53.3 18 12.6 39.7 7.4 51-4.1-21.1-16.4-42.1-32.8-63.2-49.2zm7.2-9.3c21.1 16.4 42.2 32.8 63.2 49.2 9.2-15.6 7-38.7-11-52.4-16.5-12.5-38.7-10-52.2 3.2z"
                fill={WHITE}
            /> */}
        </g>
    )
}

export function Spring() {
    return (
        <g transform="scale(0.12) translate(-460, -150)">
            <path d="m1221.7 382.4c-3.8 0-7.7.8-11.6 1.6a63.2 63.2 0 0 0 -119.9-4.5c-7-3.4-14.7-5.5-22.4-5.5a52.8 52.8 0 0 0 -35.7 14l-226.1-226.4a11 11 0 0 0 -15.4 0l-330.9 331a10.9 10.9 0 0 0 .2 15.4l330.8 330.8a11 11 0 0 0 15.4 0l330.8-330.9c2-2 3.1-4.8 3.1-7.6h81.6a59 59 0 0 0 0-117.9z" />
            <path d="m473.3 501.1c0 1 .4 2 1.1 2.7l316.3 316.2.1-318.8h-317.5z" fill="#50b748" />
            <path d="m804.6 501.1v318.6l316.2-315.9c.8-.7 1.1-1.7 1.2-2.7z" fill="#ec1c24" />
            <path
                d="m1221.7 390.2a51 51 0 0 0 -13.3 1.8l-3.8 1-1-3.9a55.6 55.6 0 0 0 -54.2-42.6c-24.6 0-46 15.8-53.3 39.2l-1.5 4.7-4.2-2.5a45 45 0 0 0 -61.2 15.8l-36.1-36.1-127.2-127.3-45.5-45.5-10.9-10.8-8.2-8.3a4.4 4.4 0 0 0 -6.1 0l-6.2 6.3-185.7 185.6 105.8-60 77.3 36.4 58-44.1 144.8 144.7c4.9 5.6 9.8 10.7 12.7 13.5l3.3 3.3c10.8 10.9 31 31.1 53.7 31.1h162.7a51.2 51.2 0 0 0 0-102.3z"
                fill={WHITE}
            />
            <path d="m862.6 286.1 174.1 173.9a6 6 0 1 0 8.5-8.5l-173-173z" />
            <path d="m1148.5 438.2c-1.4-9.4-6.7-18-17.6-23.3a46 46 0 0 0 -32-2 33.7 33.7 0 0 0 -18.7 15c-.2.5 0 1.2.4 1.5s1 .2 1.4-.2a37.2 37.2 0 0 1 42.5-7.3c15 7.3 21.5 19.8 19 38.7 0 .5.3 1 .8 1.1.5.2 1 0 1.3-.5a38.3 38.3 0 0 0 3-23z" />
            <path d="m771.2 536.8c2 3.9-16.4 17-40 40.6-26.6 26.6-59.2 64-64 67-5.3 3.4-12.3.9-14.5-2.5a943.3 943.3 0 0 1 -26.5-44.5c0-3.4 2-6.5 6-9.3 3.9-3 8-6.8 11.6-5 1.1.6 17.1 27.9 21.5 27.8 8.7-.2 71.9-78.3 84.3-79.5 2.8-.3 18-1.8 21.6 5.4z" />
            <path d="m956.5 545.1c.5 5.2-10.6 16.3-17.8 24-7.3 7.7-15.4 17.8-22 25.8a1302 1302 0 0 0 35.5 34.3c-1 3.4-5 7.7-7 8-.8.6-8.8 11.8-17.4 9.8 0 0-12.2-9-16.7-13.7-4.4-4.7-12.4-11.3-16.7-16.4-6 7.1-27.4 26.8-32 33 0 0-7 2.6-16.5-7.2-1.5-1.6-2.9-3.3-4-5.2.3-1-6.4-8-2-12.7 5.6-5.7 27.3-25.8 33.8-32.6-6-8-33-42.7-31.3-48.3 1.2-3.4 13.6-9.8 16-10.3l7-1.7c2.8 1.5 26.8 32.4 30.8 36.8 7.5-8 31-33.4 39.5-40.1 1.9.7 3.6 1.5 5.1 2.6 1.5 1 10.8 9 10.8 9l4.9 5z" />
            <g transform="translate(-259.64645 -264.84375)">
                <path
                    d="m1283.2 430.2a16 16 0 0 0 -16.2 16.1v111.8a26.6 26.6 0 1 0 32.2 0v-111.8a16 16 0 0 0 -16-16.1z"
                    fill={WHITE}
                />
                <path
                    d="m1499.3 452.2v100.9a26.6 26.6 0 1 0 32.2 0v-100.9z"
                    fill="#ec1c24"
                    transform="translate(-232.16103 4.766189)"
                />
                <g fill="none" stroke="#000">
                    <path
                        d="m1283.2 430.2a16 16 0 0 0 -16.2 16.1v111.8a26.6 26.6 0 1 0 32.2 0v-111.8a16 16 0 0 0 -16-16.1z"
                        strokeLinejoin="bevel"
                        strokeWidth="2"
                    />
                    <path d="m1266.9 535.7h19.4" strokeWidth="1.5" />
                    <path d="m1266.9 508.6h19.4" strokeWidth="1.5" />
                    <path d="m1266.9 481.4h19.4" strokeWidth="1.5" />
                </g>
            </g>
            <g transform="translate(-259.64645 -264.84375)">
                <path
                    d="m824.6 429.7c-9 0-16.3 7.3-16.3 16.4v113.2a27 27 0 1 0 32.6 0v-113.3c0-9-7.2-16.3-16.3-16.3z"
                    fill={WHITE}
                />
                <path
                    d="m1040.6 517.2v37.2a27 27 0 1 0 32.6 0v-37.2z"
                    fill="#ec1c24"
                    transform="translate(-232.16103 4.766189)"
                />
                <g fill="none" stroke="#000">
                    <path
                        d="m824.6 429.7c-9 0-16.3 7.3-16.3 16.4v113.2a27 27 0 1 0 32.6 0v-113.3c0-9-7.2-16.3-16.3-16.3z"
                        strokeLinejoin="bevel"
                        strokeWidth="2"
                    />
                    <path d="m808.2 536.7h19.7" strokeWidth="1.5" />
                    <path d="m808.2 509.2h19.7" strokeWidth="1.5" />
                    <path d="m808.2 481.7h19.7" strokeWidth="1.5" />
                </g>
            </g>
            <path d="m1081.6 179.8-16.6 36.7h13.3v58.9h6.5v-58.9h13.4z" />
            <path d="m504.1 325.3 16.6-36.7h-13.3v-58.9h-6.5v58.9h-13.4z" />
        </g>
    )
}

export function EarlySeason() {
    return (
        <g
            enableBackground="new 0 0 792 792"
            viewBox="15 15 762 762"
            xmlns="http://www.w3.org/2000/g"
            transform="scale(0.15) translate(-120, -120)"
        >
            <path
                fill={WHITE}
                d="m123.8 395.9c2.3 3.3 3.7 6.1 5.8 8.3 29.5 29.7 59.1 59.2 88.7 88.8 56.4 56.5 112.8 113 169.1 169.5 4.8 4.8 6.9 4.9 12.6 1.3 1.5-.9 2.9-2.1 4.1-3.3 7-6.9 14-13.7 21-20.7 78.2-78.3 156.4-156.7 234.7-235 5.8-5.8 6.8-8.8.4-16.7-1-1.2-2.2-2.3-3.3-3.5l-117.4-117.4c-44.4-44.6-88.8-89.2-133.3-133.7-10.5-10.5-12.4-10.3-23 .3-38.6 38.6-77.2 77.1-115.7 115.8-27.7 27.8-55.2 55.8-82.9 83.6-18.1 18.1-36.5 35.8-54.6 53.9-2.3 2.3-3.9 5.4-6.2 8.8z"
            />
            <path d="m123.8 395.9c2.3-3.4 3.9-6.5 6.2-8.9 18.1-18 36.5-35.8 54.6-53.9 27.7-27.8 55.2-55.8 82.9-83.6 38.5-38.6 77.1-77.2 115.7-115.8 10.6-10.6 12.5-10.7 23-.3 44.5 44.5 88.9 89 133.3 133.6 39.1 39.2 78.3 78.3 117.4 117.4 1.1 1.1 2.3 2.2 3.3 3.5 6.4 7.9 5.5 10.9-.4 16.7-78.3 78.2-156.5 156.6-234.7 235-6.9 6.9-13.9 13.8-21 20.7-1.3 1.2-2.6 2.4-4.1 3.3-5.8 3.6-7.9 3.4-12.6-1.3-56.3-56.4-112.7-112.9-169-169.3-29.6-29.6-59.2-59.2-88.7-88.8-2.1-2.2-3.6-5-5.9-8.3zm13.5.3c1.3 1.5 2 2.3 2.8 3.1 44.9 44.9 89.8 89.9 134.6 134.8 38.6 38.7 77.3 77.4 115.8 116.1 3 3 5 3.6 8.3.3 83.5-83.7 167.1-167.4 250.7-251 .8-.8 1.4-1.8 2.5-3.3zm105.5-108.2c.3.3.5.7.8 1 .8-.4 1.6-.8 2.3-1.2 24.4-13.9 48.9-27.7 73.3-41.7 3.5-2 6-2.5 9.8 0 12.8 8.3 26 16 38.9 24.1 2.5 1.5 4.1 1.5 6.4-.1 24.6-15.9 49.3-31.5 73.8-47.5 3.7-2.4 5.7-1.7 8.4 1 18.1 18.3 36.3 36.5 54.5 54.7 18.5 18.6 37.1 37.1 55.6 55.7 2.7 2.7 5.9 5.4 9.7 3.7 2.7-1.2 5.4-3.9 6.7-6.7 1.8-3.8-1-7-3.7-9.7-59.7-59.8-119.3-119.5-179-179.3-5.6-5.6-5.8-5.5-11.5.1-37.5 37.6-75 75.2-112.6 112.7-10.9 11.3-22.2 22.2-33.4 33.2z" />
            <path
                fill={WHITE}
                d="m242.8 288c11.2-11 22.5-21.9 33.6-33 37.6-37.5 75.1-75.1 112.6-112.7 5.7-5.7 5.9-5.7 11.5-.1 59.7 59.8 119.3 119.5 179 179.3 2.7 2.7 5.5 5.8 3.7 9.7-1.3 2.7-4 5.4-6.7 6.7-3.8 1.7-6.9-1-9.7-3.7-18.5-18.6-37.1-37.1-55.6-55.7-18.2-18.2-36.4-36.4-54.5-54.7-2.8-2.8-4.7-3.5-8.4-1-24.5 16-49.2 31.6-73.8 47.5-2.4 1.5-4 1.6-6.4.1-12.9-8.1-26.1-15.8-38.9-24.1-3.8-2.5-6.4-2-9.8 0-24.4 14-48.9 27.8-73.3 41.7-.8.4-1.6.8-2.3 1.2-.4-.6-.7-.9-1-1.2z"
            />
            <circle cx="394.2" cy="498.4" r="73.1" />
            <g fill={WHITE}>
                <circle cx="395.4" cy="463.1" r="9" />
                <path d="m376.6 477.8v6.5h10.2v46.8h-10.2v7.4h36.3v-6.7h-8.6v-54z" />
            </g>
        </g>
    )
}
