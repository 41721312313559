import { format as formatDate, parse as parseDate } from 'date-fns'
import * as FunctionUtils from './function'
import * as NumberUtils from './number'
import * as ObjectUtils from './object'
import { ASC, DESC, NONE } from 'constants/sortings'

const IdentityParam = {
    parse: FunctionUtils.identity,
    format: FunctionUtils.identity,
    isEmpty: FunctionUtils.isNotDefined,
}

export const NumberParam = {
    parse(string) {
        if (string == null) {
            return
        }

        return Number(string)
    },
    format: FunctionUtils.identity,
    isEmpty(number) {
        return NumberUtils.isNotValid(number)
    },
}

export const BooleanParam = {
    parse(string) {
        if (string === '0' || string === 'false') {
            return false
        }

        return Boolean(string)
    },
    format: FunctionUtils.identity,
    isEmpty(boolean) {
        return typeof boolean !== 'boolean'
    },
}

export const StringParam = IdentityParam

export const ArrayParam = {
    parse(values) {
        const items = Array.isArray(values) ? values : [values]

        return items.filter(Boolean)
    },
    format(values) {
        if (!Array.isArray(values)) {
            return []
        }

        return values.filter(FunctionUtils.isDefined)
    },
    isEmpty(values) {
        return !Array.isArray(values) || values.length === 0
    },
}

export const SetParam = {
    parse(values) {
        return new Set(ArrayParam.parse(values))
    },
    format(values) {
        if (!values) {
            return []
        }

        return Array.from(values).filter(FunctionUtils.isDefined)
    },
    isEmpty(values) {
        return !(values instanceof Set) || values.size === 0
    },
}

export const DateParam = {
    parse(string) {
        return string && parseDate(string, DATE)
    },
    format(date) {
        return date && formatDate(date, DATE)
    },
    isEmpty(date) {
        return !(date instanceof Date)
    },
}

export const TimeParam = {
    parse(string) {
        return string && parseDate(string, TIME)
    },
    format(date) {
        return date && formatDate(date, TIME)
    },
    isEmpty(date) {
        return DateParam.isEmpty(date)
    },
}

export const SortingParam = {
    parse(sorting) {
        if (typeof sorting !== 'string') {
            return [null, NONE]
        }

        if (sorting.startsWith('-')) {
            return [sorting.substring(1), DESC]
        }

        if (sorting) {
            return [sorting, ASC]
        }

        return [null, NONE]
    },
    format([name, order]) {
        switch (order) {
            case DESC:
                return '-' + name
            case ASC:
                return name
            default:
                return
        }
    },
    isEmpty([name, order]) {
        return order === NONE
    },
}

export const PageParam = {
    parse(string) {
        const page = NumberParam.parse(string)

        return page < 2 ? undefined : page
    },
    format(page) {
        return page < 2 ? undefined : page
    },
    isEmpty(page) {
        return NumberUtils.isNotValid(page) || page === 1
    },
}

export function convertToURLSearchParams(object) {
    if (object instanceof URLSearchParams) {
        return object
    }

    return Object.entries(ObjectUtils.clean(object)).reduce((params, [key, value]) => {
        if (Array.isArray(value)) {
            for (const val of value) {
                params.append(key, val)
            }
        } else {
            params.set(key, value)
        }

        return params
    }, new URLSearchParams())
}

export function fromString(string) {
    const search = new URLSearchParams(string)
    const params = new Map()

    for (const [key, value] of search.entries()) {
        if (params.has(key)) {
            const param = params.get(key)

            if (Array.isArray(param)) {
                param.push(value)
            } else {
                params.set(key, [param, value])
            }
        } else {
            params.set(key, value)
        }
    }

    return Object.fromEntries(params)
}

// Constants
const DATE = 'YYYY-MM-DD'
const TIME = 'HH:mm'
